<template>
  <img
    v-if="url"
    :src="renewUrl"
    alt=""
    @click="showImages"
    class="mt-1"
    style="height: 50px"
  />
</template>

<script>
import { api as viewerApi } from "v-viewer";

export default {
  props: {
    url: {
      type: [String],
      default: () => null,
    },
  },
  name: "TimekeepingImage",
  data: () => ({}),
  computed: {
    renewUrl() {
      if (!this.url) {
        return null;
      }
      if (this.url.startsWith("/")) {
        return this.url;
      }
      return `${process.env.VUE_APP_FILE_CDN_URL}/${this.url}`;
    },
    imageUrls() {
      return [this.renewUrl];
    },
  },
  methods: {
    showImages() {
      const index = 0;
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.imageUrls,
      });
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
